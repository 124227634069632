import React, { SVGAttributes } from 'react';

interface SVGPlaySerieProps extends SVGAttributes<SVGElement> {
    isHovered?: boolean;
}

export function SVGPlaySerie({ isHovered = false, ...rest }: SVGPlaySerieProps): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={90} height={90} viewBox="0 0 90 90" {...rest}>
            <path
                d="M44.8 89.7q-12.1 0-22.4-6.1-10.3-6-16.3-16.3Q0 57 0 44.8q0-12.1 6.1-22.4 6-10.3 16.3-16.3Q32.7 0 44.8 0 57 0 67.3 6.1q10.3 6 16.3 16.3 6.1 10.3 6.1 22.4 0 12.2-6.1 22.5-6 10.3-16.3 16.3-10.3 6.1-22.5 6.1z"
                style={{
                    fill: '#fff',
                }}
            />
            {isHovered ? (
                <path
                    d="M66.2 41.7q2.1 1.2 2.1 3.7 0 2.4-2.1 3.6L35.7 66.5q-2.1 1.3-4.2 0-2.1-1.2-2.1-3.6V26.8q0-2.4 2.1-3.7 2.1-1.2 4.2 0z"
                    style={{
                        fill: '#0028ce',
                    }}
                />
            ) : (
                <path
                    fillRule="evenodd"
                    d="M67.1 41.6q2.2 1.3 2.2 3.8t-2.2 3.8L35.3 67.4q-2.2 1.3-4.4 0-2.1-1.2-2.1-3.8V26q0-2.5 2.1-3.8 2.2-1.2 4.4 0zM34.5 59.4q0 .5.6.9.5.3 1.1 0l24.4-14q.3-.3.4-1 .1-.6-.4-.9L36.2 29.7q-.6-.4-1.1-.1-.6.3-.6 1z"
                    style={{
                        fill: '#000',
                    }}
                />
            )}
        </svg>
    );
}
