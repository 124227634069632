import React, { SVGAttributes } from 'react';

export function SVGPlay(props: SVGAttributes<SVGElement>): JSX.Element {
    return (
        <svg width={90} height={90} {...props}>
            <path
                d="M45 77.875c-6.281 0-12.094-1.57-17.438-4.71-5.343-3.142-9.585-7.384-12.726-12.727-3.14-5.344-4.711-11.157-4.711-17.438 0-6.281 1.57-12.094 4.71-17.437 3.142-5.344 7.384-9.586 12.727-12.727C32.906 9.696 38.72 8.126 45 8.126c6.281 0 12.094 1.57 17.437 4.71 5.344 3.14 9.586 7.383 12.727 12.727 3.14 5.343 4.71 11.156 4.71 17.437 0 6.281-1.57 12.094-4.71 17.438-3.14 5.343-7.383 9.585-12.727 12.726-5.343 3.14-11.156 4.711-17.437 4.711zM14.625 43c0 5.531 1.36 10.617 4.078 15.258 2.719 4.64 6.422 8.32 11.11 11.039 4.687 2.719 9.773 4.078 15.257 4.078 5.485 0 10.547-1.36 15.188-4.078 4.64-2.719 8.32-6.422 11.039-11.11 2.718-4.687 4.078-9.773 4.078-15.257 0-5.485-1.36-10.547-4.078-15.188-2.719-4.64-6.422-8.32-11.11-11.039-4.687-2.718-9.773-4.078-15.257-4.078-5.485 0-10.547 1.36-15.188 4.078-4.64 2.719-8.32 6.422-11.039 11.11-2.719 4.687-4.078 9.75-4.078 15.187zm46.687-2.531c1.125.656 1.688 1.64 1.688 2.953 0 1.312-.563 2.297-1.688 2.953l-24.75 14.203c-1.125.656-2.25.656-3.375 0s-1.687-1.64-1.687-2.953v-29.25c0-1.312.562-2.297 1.687-2.953 1.125-.656 2.25-.656 3.375 0l24.75 15.047zM36 54.25c0 .281.14.516.422.703.281.188.562.188.844 0L56.25 44.125c.187-.187.305-.445.351-.773.047-.328-.07-.586-.351-.774l-18.984-11.39c-.282-.188-.563-.211-.844-.07-.281.14-.422.398-.422.773V54.25z"
                fill="#FFF"
            />
        </svg>
    );
}
