/**
 * Keyboard event.key constants.
 * Values taken from:
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 * and for _IE from:
 * https://msdn.microsoft.com/en-us/library/gg305568(v=vs.85).aspx
 * Browser support:
 * http://caniuse.com/#feat=keyboardevent-key
 */
enum EventKeys {
    BACKSPACE = 'Backspace',
    TAB = 'Tab',
    ENTER = 'Enter',
    ESC = 'Escape',
    SPACE = ' ',
    END = 'End',
    HOME = 'Home',
    LEFT = 'ArrowLeft',
    UP = 'ArrowUp',
    RIGHT = 'ArrowRight',
    RIGHT_IE = 'Right',
    DOWN = 'ArrowDown',
    DELETE = 'Delete',
    COMMA = ',',
    F2 = 'F2',
    A = 'a',
    C = 'c',
    V = 'v',
    X = 'x',
    Y = 'y',
    Z = 'z',
}

export default EventKeys;
