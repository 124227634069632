import React, { SVGAttributes } from 'react';
import { WithT } from 'i18next';

export function SVGCloseIcon(props: SVGAttributes<SVGElement> & WithT): JSX.Element {
    const title = props.t('common:close');
    return (
        <svg width={26} height={26} {...props}>
            <title>{title}</title>
            <path
                d="M16.126 13.321l9.115 9.115a1.983 1.983 0 01-2.805 2.805l-9.115-9.115-9.114 9.115a1.983 1.983 0 01-2.805-2.805l9.115-9.115-9.115-9.114a1.983 1.983 0 112.805-2.805l9.114 9.115 9.115-9.115a1.983 1.983 0 012.805 2.805l-9.115 9.114z"
                fill="black"
                fillRule="evenodd"
            />
        </svg>
    );
}
