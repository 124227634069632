import React, { SVGAttributes } from 'react';

export function SVGVisibility(props: SVGAttributes<SVGElement>): JSX.Element {
    return (
        <svg width={24} height={16} {...props}>
            <path
                d="M24 7.467c-.097-.192 0 .156 0 1.066 0-.156-.097.192 0 0-1.486 2.626-3.111 4.308-5 5.334C16.75 15.362 14.472 16 12 16c-2.472 0-4.75-.638-7-2.133-1.889-1.026-3.514-2.708-5-5.334.097.192 0-.156 0 0 0-.91.097-1.258 0-1.066C1.486 4.84 3.111 3.159 5 2.133 7.25.638 9.528 0 12 0c2.472 0 4.75.638 7 2.133 1.889 1.026 3.514 2.708 5 5.334zM12 14c2.111 0 4.049-.546 6-2.182 1.576-.549 2.972-2.01 4-4.363-.639-.599-1.424-1.595-2-2.182-1.285-1.155-2.333-1.856-3-2.182.278.887.667 2.112 1 3.273-.333 1.29-.59 2.293-1 3.272a12.982 12.982 0 01-2 2.182c-1.028.492-1.972.765-3 1.091-1.028-.326-1.972-.599-3-1.09a12.982 12.982 0 01-2-2.183c-.41-.98-.667-1.983-1-3.272.333-.807.556-1.751 1-2.182 0 .224.23.807 1 1.09.146.691.694.935 1 1.092.972-.157 1.52-.4 2-1.091.438-.284.667-.867 1-1.091-.333-1.134-.556-1.71-1-2.182-1.889.177-3.625.797-5 2.182-1.792.74-3.056 2.113-4 3.273 1.028 2.353 2.424 3.814 4 4.363C7.951 13.454 9.889 14 12 14z"
                fillRule="evenodd"
            />
        </svg>
    );
}
