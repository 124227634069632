import React, { SVGProps } from 'react';

export type SVGCheckboxIconProps = SVGProps<SVGSVGElement> & {
    /**
     *  Whether or not the current checkbox is rendered in checked mode, unchecked or mixed mode.
     *  See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/checkbox_role#Associated_WAI-ARIA_Roles_States_and_Properties
     */
    isChecked?: boolean | 'mixed';

    /**
     *  Whether or not the current checkbox is rendered in disabled mode.
     */
    disabled?: boolean;

    /**
     * CSS classnames to add to the element
     */
    className?: string;
};

export function SVGCheckboxIcon({ isChecked, className, disabled, ...svgProps }: SVGCheckboxIconProps): JSX.Element {
    const title = 'Checkbox';
    const colorHexCode = '#000';

    return (
        <svg width={21} height={21} className={className} {...svgProps}>
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <path stroke={colorHexCode} strokeWidth={2} opacity={disabled ? 0.25 : 1} d="M1 1h19v19H1z" />
                {isChecked === true && <path fill={colorHexCode} opacity={1} d="M5 5h11v11H5z" />}
                {isChecked === 'mixed' && <path fill={colorHexCode} opacity={1} d="M5 5h11v11z" />}
            </g>
        </svg>
    );
}
