import React, { SVGAttributes } from 'react';

export function SVGRetrySearch(props: SVGAttributes<SVGElement>): JSX.Element {
    return (
        <svg width={65} height={65} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M5.352 59.96c-.235.235-.352.548-.352.938 0 .39.117.742.352 1.055l2.695 2.578c.312.313.664.469 1.055.469.39 0 .703-.156.937-.469l14.297-14.18c.234-.312.351-.625.351-.937v-1.64a25.175 25.175 0 0 0 7.383 4.394 23.568 23.568 0 0 0 8.555 1.582c4.453 0 8.535-1.094 12.246-3.281a24.627 24.627 0 0 0 8.848-8.848c2.187-3.71 3.28-7.793 3.28-12.246s-1.093-8.535-3.28-12.246A24.627 24.627 0 0 0 52.87 8.28C49.16 6.094 45.078 5 40.625 5S32.09 6.094 28.379 8.281a24.627 24.627 0 0 0-8.848 8.848c-2.187 3.71-3.281 7.793-3.281 12.246 0 2.969.527 5.82 1.582 8.555a25.175 25.175 0 0 0 4.395 7.382h-1.641c-.39 0-.703.118-.938.352L5.352 59.961zm35.273-11.835c-3.36 0-6.484-.84-9.375-2.52a18.617 18.617 0 0 1-6.856-6.855c-1.68-2.89-2.519-6.016-2.519-9.375 0-3.36.84-6.484 2.52-9.375a18.617 18.617 0 0 1 6.855-6.855c2.89-1.68 6.016-2.52 9.375-2.52 3.36 0 6.484.84 9.375 2.52A18.617 18.617 0 0 1 56.855 20c1.68 2.89 2.52 6.016 2.52 9.375 0 3.36-.84 6.484-2.52 9.375A18.617 18.617 0 0 1 50 45.605c-2.89 1.68-6.016 2.52-9.375 2.52z"
                    fill="#FFED00"
                    fillRule="nonzero"
                />
                <path
                    d="M.352 54.96c-.235.235-.352.548-.352.938 0 .39.117.742.352 1.055l2.695 2.578c.312.313.664.469 1.055.469.39 0 .703-.156.937-.469l14.297-14.18c.234-.312.351-.625.351-.937v-1.64a25.175 25.175 0 0 0 7.383 4.394 23.568 23.568 0 0 0 8.555 1.582c4.453 0 8.535-1.094 12.246-3.281a24.627 24.627 0 0 0 8.848-8.848c2.187-3.71 3.28-7.793 3.28-12.246s-1.093-8.535-3.28-12.246A24.627 24.627 0 0 0 47.87 3.28C44.16 1.094 40.078 0 35.625 0S27.09 1.094 23.379 3.281a24.627 24.627 0 0 0-8.848 8.848c-2.187 3.71-3.281 7.793-3.281 12.246 0 2.969.527 5.82 1.582 8.555a25.175 25.175 0 0 0 4.395 7.382h-1.641c-.39 0-.703.118-.938.352L.352 54.961zm35.273-11.835c-3.36 0-6.484-.84-9.375-2.52a18.617 18.617 0 0 1-6.856-6.855c-1.68-2.89-2.519-6.016-2.519-9.375 0-3.36.84-6.484 2.52-9.375a18.617 18.617 0 0 1 6.855-6.855c2.89-1.68 6.016-2.52 9.375-2.52 3.36 0 6.484.84 9.375 2.52A18.617 18.617 0 0 1 51.855 15c1.68 2.89 2.52 6.016 2.52 9.375 0 3.36-.84 6.484-2.52 9.375A18.617 18.617 0 0 1 45 40.605c-2.89 1.68-6.016 2.52-9.375 2.52z"
                    fill="#000"
                />
            </g>
        </svg>
    );
}
