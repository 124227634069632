import React, { SVGProps } from 'react';
import { WithT } from 'i18next';

export type SVGRadioButtonIconProps = SVGProps<SVGSVGElement> &
    WithT & {
        /**
         * Whether or not the current radio button icon is rendered in checked mode or unchecked.
         */
        isSelected?: boolean;

        /**
         *  Whether or not the current radio button icon is rendered in disabled mode.
         */
        disabled?: boolean;

        /**
         * CSS classnames to add to the element
         */
        className?: string;
    };

export function SVGRadioButtonIcon({
    isSelected,
    className,
    disabled,
    t,
    ...svgProps
}: SVGRadioButtonIconProps): JSX.Element {
    const title = t(isSelected ? 'common:radioButtonSelected' : 'common:radioButtonNotSelected');
    let opacity = 1;
    if (disabled) {
        opacity /= 4;
    }
    return (
        <svg width={24} height={24} className={className} {...svgProps}>
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <circle stroke="black" opacity={opacity} strokeWidth={2} fillRule="nonzero" cx={12} cy={12} r={10.5} />
                {isSelected && <circle fill="black" opacity={opacity} cx={12} cy={12} r={6.5} />}
            </g>
        </svg>
    );
}
